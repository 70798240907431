<template>
    <div class="not-found-page">
        <div class="container">
            <h1>{{ $t("public.notFoundPage.pageNotFound") }}</h1>
            <p>{{ $t("public.notFoundPage.weCantFind") }} <span>{{searchedUrl}}</span></p>
            <router-link to="/"><button class="button button--primary">{{ $t("public.notFoundPage.backToHomepage") }}</button></router-link>
        </div>
    </div>


</template>

<script>
    export default {
        computed: {
            searchedUrl() {
                return window.location.hash.substring(1)
            } 
        }
    }
</script>

<style scoped>
    .not-found-page {
        padding-top: 100px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgb(248, 250, 251);
    }

    p {
        margin-bottom: 50px;
    }

    span {
        color: gray;
    }

</style>